<script setup>
import { cssProp } from "@/utils/css";
import { useValidation } from "@/composables/useValidation";
import { transl } from "@/composables/useTranslation";

const props = defineProps(["modelValue", "rules", "type", "hasFocus"]);
const emit = defineEmits([
  "update:modelValue",
  "update:hasFocus",
  "blur",
  "focus",
  "enter",
  "input",
]);
const attrs = useAttrs();

const el = shallowRef(null);
const text = shallowRef("");
const uniqid = getCurrentInstance().uid;

watchEffect(() => {
  text.value = props.modelValue;
});
watchEffect(() => {
  emit("update:modelValue", text.value);
});

const hasFocus = shallowRef(false);
const isEmpty = computed(() => !text.value || text.value?.toString().trim()?.length == 0);
const isActive = computed(
  () => hasFocus.value || !isEmpty.value || computedAttrs.persistentLabel
);

const computedAttrs = reactive({
  focused: attrs.focused || false,
  clearable: attrs.clearable || false,
  type: computed(() => attrs.type || props.type || "input"),
  readonly: computed(() => attrs.readonly || props.readonly || false),
  validateOnInput: "validate-on-input" in attrs || false,
  hideDetails: "hide-details" in attrs || false,
  persistentLabel: "persistent-label" in attrs || false,
});

const computedStyle = computed(() => ({
  width: cssProp(props.width),
  color: cssProp(attrs.color),
}));

// validation
const form = inject("form", null);

const validationModel = useValidation(text, props.rules);
const { hasErrors, errors, validate } = validationModel;

onMounted(() => {
  if (form) form?.register(validationModel);

  if (computedAttrs.focused) setTimeout(() => focus(), 500);
});

onUnmounted(() => {
  if (form) form.unregister(validationModel);
});

const focus = () => el.value.focus();
const blur = () => el.value.blur();

function handleInput() {
  if (computedAttrs.validateOnInput) validate();

  emit("input", text.value);
}

function handleFocus() {
  hasFocus.value = true;
  emit("focus");
  emit("update:hasFocus", true);
}

async function handleBlur() {
  hasFocus.value = false;
  emit("blur");
  emit("update:hasFocus", false);
  await validate();
}

function handleKeyPress(e) {
  if (e.key == "Enter") {
    blur();
    emit("enter");
  }
}

function clear() {
  text.value = "";
}

// expose
defineExpose({
  hasErrors,
  errors,
  validate,
  focus,
  blur,
});
</script>
<template>
  <div class="v-text-field" :class="{ error: hasErrors }" :style="computedStyle">
    <div class="width-100 height-100">
      <div class="row-el">
        <slot name="prepend" :hasFocus="hasFocus"></slot>

        <div class="input-el">
          <!-- label -->
          <v-label :class="{ active: isActive, error: hasErrors }">
            {{ attrs.label }}
          </v-label>

          <input
            v-model="text"
            ref="el"
            spellcheck="false"
            autocomplete="new-password"
            :id="uniqid"
            :type="computedAttrs.type"
            :maxlength="attrs.maxlength"
            :placeholder="transl(attrs.placeholder)"
            :readonly="attrs.readonly"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
            @keypress="handleKeyPress"
          />
        </div>

        <slot name="append"></slot>

        <Transition name="appear" appear>
          <v-btn v-if="computedAttrs.clearable && !isEmpty" @click="clear()" icon="sm"
            >X
          </v-btn>
        </Transition>
      </div>
    </div>
    <slot name="error">
      <!-- error -->
      <v-error :class="{ active: hasErrors }" class="error-el"> {{ transl(errors[0]) }}</v-error>
    </slot>
  </div>
</template>

<style scoped>

.v-text-field {
  width: auto;
  height: auto;
  display: block;
  /* display:inline-block; */
  position: relative;
  background-color: var(--forms-bg, #fff);
  border-radius: 5px;
  outline: 1px solid var(--forms-border, #b3b3b3);
  padding: 10px 20px;
  transition: border-color 0.2s ease-in-out;
  box-sizing: border-box;
}

.v-text-field.error {
  outline: 2px solid rgb(255, 104, 28) !important;
}

.v-text-field:not(.error):focus-within {
  outline: 2px solid var(--forms-border-focus, #0957d0);
}
input {
  font-family: "Montserrat", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: var(--text-color, #000);
  font-size: 20px;
  height: 100%;
  width: 100%;
  /* background-color: yellow; */
  border: 0;
}

input:focus {
  outline: none;
}

input[type="password"] {
  font: small-caption;
  font-size: 36px;
}

.error-el{
  margin-left: 20px;
}

.v-text-field:not(:focus-within):hover {
  /* outline: 1.5px solid var(--grey-light5,  #0957d0); */
}

/* .error .label :deep() .lable-content,
.error .label :deep() .lable-content.active {
  color: rgb(255, 104, 28);
} */

.v-text-field[flat] {
  background-color: transparent;
  border-radius: 0px;
  border: none;
  padding: 0px;
  outline: none;
}

.v-text-field[flat]:focus-within {
  border-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;
}

.v-text-field[flat]:hover {
  border-color: transparent;
  border: none;
  outline: none;
}
/* 
.v-text-field[dense] {
  height: 100%;
  max-height: 60px;
}

.v-text-field[rounded] {
  border-radius: 30px;
} */

.appear-enter-from {
  transition: all 0.2s ease;
  transform: rotate(0deg) scale(0.5);
  opacity: 0.3;
}

.appear-enter-to,
.appear-leave-from {
  transition: all 0.2s ease;
  transform: rotate(90deg) scale(1);
  opacity: 1;
}

.appear-leave-to {
  transition: all 0.2s ease;
  transform: rotate(180deg) scale(0);
  opacity: 0;
}

.row-el {
  display: flex;
  /* flex: 1; */
  align-items: center;
  width: 100%;
  height: 100%;
  border: 0;
}

.input-el {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
.flex-width {
  display: flex;
  flex: 1;
}
.align-center {
  align-items: center;
}

* {
  box-sizing: border-box;
}
</style>